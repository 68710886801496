import React from 'react'
import Form from '../../Components/Form/Form'

export default function AddArticle() {
  return (
    <>

    <Form />

    </>
  )
}
