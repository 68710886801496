import React, {useState, useRef} from 'react';
import {useDispatch} from 'react-redux';
import './Form.css';

export default function Form() {

  // const [article, setArticle] = useState({
  //   title: '',
  //   body: '', 
  // })

  const dispatch = useDispatch();

  const handleForm = e => {
    e.preventDefault();

    const newArticle = {
      title: inputsRef.current[0].value,
      body: inputsRef.current[1].value,
    }

    dispatch({
      type: 'ADDARTICLE',
      // payload: article
      payload: newArticle
    })

    e.target.reset();

    // setArticle({
    //   title: "",
    //   body: ""
    // })
  };

  // const handleInputs = e => {

  //   if (e.target.classList.contains('inp-title')) {
  //     const newObjState = {...article, title: e.target.value};
  //     setArticle(newObjState);
  //   } 
  //   else if (e.target.classList.contains('inp-body')) {
  //     const newObjState = {...article, body: e.target.value};
  //     setArticle(newObjState);
  //   }


  // }

  const inputsRef = useRef([]);

  const addRefs = el => {
    if (el && !inputsRef.current.includes(el)) {
      inputsRef.current.push(el);
    }
  }

  return (
    <>
      <h1 className="title-form">Écrivez un article</h1>

      <form onSubmit={handleForm} className="container-form">
        <label htmlFor='title'>Titre</label>
        <input 
        // onChange={handleInputs}
        // value={article.title}
        ref={addRefs}
        type="text" 
        id="title"
        className='inp-title'
        placeholder='Entrex votre titre' />
        
        <label htmlFor='article'>Votre article</label>
        <textarea 
        // onChange={handleInputs}
        // value={article.body}
        ref={addRefs}
        id="article"
        className='inp-body'
        placeholder='Entrex votre article'></textarea>

        <button>Envoyez l'article</button>
      </form>
    </>
  )
}
