import { configureStore, combineReducers, applyMiddleware } from '@reduxjs/toolkit';
import articleReducer from './article/articleReducer'
import thunk from 'redux-thunk' 


const rootReducers = combineReducers({
  articleReducer, 
})

const store = configureStore({
  reducer:rootReducers, 
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});


export default store